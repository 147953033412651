/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.img-logo-div{
    margin: auto !important;
    width: 30% !important;
}

.btn-menu{
    font-size: 200% !important;
    color: #3ae18f;
}

.toolbar-header{
    padding: 3% 0 !important;
}

.btn-principal{
    --background: #3ae18f !important;
}

.inputs{
    border: 1px solid #C4C4C4;
    margin-top: 5%;
    border-radius: 10px;
}

.modal-filtro{
    height: 63%;
    position: absolute;
    top: 37%;
    border-radius: 10px;
}

.table-bordered, .table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
    border: 0 !important;
    font-size: 105%;
}

.table-bordered{
    width: 85% !important;
}

.table-bordered > thead > tr > th{
    color: #FFFFFF;
}

.table-bordered > tbody > tr > td{
    color: #33385A;
    font-weight: bold;
}

.monthview-disabled{
    color: #c7c7c7 !important;
}

.monthview-current{
    background-color: transparent !important;
}

.monthview-selected{
    background-color: #D97F90 !important;
    color: #FFFFFF;
}

.modal-cartao{
    height: 50%;
    margin-top: 89%;
    border-radius: 10px;
}

.exame-toast{
    height: 100px;
    font-size: 130%;
    text-align: center;
    color: #E34273;
    --background: white;
    font-weight: bold;
}